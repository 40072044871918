import Autocomplete from "lib/Select/Autocomplete";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DataTable from "../DataTable";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers";
import { useMemo, useRef, useState } from "react";
import { VerityFieldCI } from "./VerityFieldCI";
import { ApplicationsCell } from "./ApplicationsCell";
import {
  useVerityFieldsBySeason,
  useVerityFieldsRefresh,
} from "./useVerityFieldsBySeason";
import { StatusCell } from "./StatusCell";
import { useVerityFieldEnroll } from "./useVerityFieldEnroll";
import { useVerityFieldUpdate } from "./useVerityFieldUpdate";
import { TillageCell } from "./TillageCell";
import axios from "axios";

const availablePrograms = [
  {
    value: "a18c10f5-22e9-488e-a344-dd939fc794c9",
    label: "Corn to Ethanol - FDCIC-2023",
  },
];

export function VerityModule() {
  const { t } = useTranslation();
  const { org, season } = useOrgState();
  const enrollingFieldId = useRef("");
  const [fieldId, setFieldId] = useState("");
  const [selectedProgram, setSelectedProgram] = useState(availablePrograms[0]);
  const [shouldRefresh, setShouldRefresh] = useState<boolean>();
  const [showUnenroll, setShowUnenroll] = useState(false);
  const verityQ = useVerityFieldsBySeason({ org, seasonId: season?.id });
  const verityRefreshQ = useVerityFieldsRefresh({
    org,
    seasonId: season?.id,
    enabled: shouldRefresh,
    onSettled: () => {
      // reset refresh state when settled
      setShouldRefresh(false);
    },
  });
  const verityFieldEnrollMutation = useVerityFieldEnroll({
    org,
    seasonId: season?.id,
    programId: selectedProgram?.value,
  });

  const verityFieldUpdateMutation = useVerityFieldUpdate({
    org,
    seasonId: season?.id,
    programId: selectedProgram?.value,
  });

  const columns = useMemo(
    () => [
      {
        Header: t("common.status"),
        accessor: "status",
        Cell: (data: { row: { original: VerityFieldCI }; value: string }) => (
          <div>
            <StatusCell
              {...data}
              org={org}
              seasonId={season?.id}
              programId={selectedProgram?.value}
              disabled={verityFieldEnrollMutation.isLoading}
              isLoading={
                data.row?.original?.fieldId === enrollingFieldId.current &&
                verityFieldEnrollMutation.isLoading
              }
              onClick={() => {
                const fieldId = data.row?.original?.fieldId;
                const statusEn = data.row?.original?.statusEn;
                if (statusEn === "enroll") {
                  enrollingFieldId.current = fieldId;
                  setFieldId(fieldId);
                  verityFieldEnrollMutation.mutate(fieldId, {
                    onSettled: () => {
                      enrollingFieldId.current = "";
                    },
                  });
                } else if (statusEn === "update") {
                  verityFieldUpdateMutation.mutate(fieldId);
                }
              }}
            />
            {verityFieldEnrollMutation.isError &&
            data.row?.original?.fieldId === fieldId ? (
              <Alert
                onClose={() => {
                  verityFieldEnrollMutation.reset();
                }}
                style={{ position: "absolute", zIndex: 9 }}
                severity="error"
              >
                {verityFieldEnrollMutation.error}
              </Alert>
            ) : null}
          </div>
        ),
      },
      {
        Header: t("common.field"),
        accessor: "field",
        Cell: ({
          row,
          value,
        }: {
          row: { original: VerityFieldCI };
          value: string;
        }) => {
          return (
            <Box
              sx={{ mr: 1 }}
              style={{
                display: "flex",
                flexDirection: "column",
                float: "left",
              }}
            >
              <span>{`${value}`}</span>
              <small>{row.original?.farm}</small>
            </Box>
          );
        },
      },
      {
        Header: t("common.crop"),
        accessor: "plantedCrop",
      },
      {
        Header: t("verity.coverCrop"),
        accessor: "plantedCoverCrop",
        Cell: ({ value }: { value: string }) => {
          return value || "--";
        },
      },
      {
        Header: t("verity.tillage"),
        accessor: "tilledOperations",
        Cell: TillageCell,
      },
      {
        Header: t("verity.manureApplications"),
        accessor: "manureApplications",
        Cell: ApplicationsCell,
      },
      {
        Header: t("verity.otherApplications"),
        accessor: "otherApplications",
        Cell: ApplicationsCell,
      },
      {
        Header: t("verity.harvestedYieldAvg"),
        accessor: "harvestedYieldAvg",
        Cell: ({ row }: { row: { original: VerityFieldCI } }) => {
          return row.original.harvestedYieldAvg
            ? `${row.original.harvestedYieldAvg} ${
                row.original.harvestedYieldUom ?? ""
              }`
            : "--";
        },
      },
      {
        Header: t("verity.ciScore"),
        accessor: "score",
        Cell: ({ row }: { row: { original: VerityFieldCI } }) => {
          const textColor =
            row.original.statusEn === "certified" ? "success.main" : undefined;
          return row.original.score ? (
            <Box color={textColor}>{`${row.original.score.toLocaleString()} ${
              row.original.scoreUom ?? ""
            }`}</Box>
          ) : (
            "--"
          );
        },
      },
    ],
    [
      fieldId,
      org,
      season?.id,
      selectedProgram?.value,
      t,
      verityFieldEnrollMutation,
      verityFieldUpdateMutation,
    ]
  );
  return (
    <Stack style={{ height: "100%" }} spacing={1}>
      <Typography component="h2" variant="h5">
        Verity Module
      </Typography>
      <Paper
        style={{
          height: "100%",
        }}
        spacing={3}
        component={Stack}
        sx={{ p: 3 }}
      >
        <Typography>{t("verity.description")}</Typography>
        {verityQ.isError || verityRefreshQ.isError ? (
          <Alert severity="error">
            {verityRefreshQ.error || verityQ.error}
          </Alert>
        ) : null}
        <DataTable
          isLoading={verityQ.isFetching || verityRefreshQ.isFetching}
          components={{
            HeaderLeft: (
              <div style={{ paddingTop: "1px", flex: 1 }}>
                <Autocomplete
                  InputProps={{
                    onChange: (e) => {
                      /* easter egg for bb */
                      const val = e.target.value;
                      if (val === "8675309") {
                        setShowUnenroll(true);
                      } else {
                        setShowUnenroll(false);
                      }
                    },
                  }}
                  label={t("verity.program")}
                  value={selectedProgram ?? null}
                  options={availablePrograms}
                  onChange={(_e, opt: { label: string; value: string }) => {
                    setSelectedProgram(opt);
                  }}
                />
              </div>
            ),
            HeaderRight: (
              <>
                <Button
                  disabled={verityRefreshQ.isFetching}
                  onClick={async () => {
                    verityFieldEnrollMutation.reset();
                    setShouldRefresh(true);
                  }}
                  variant="outlined"
                >
                  {t("verity.refresh")}
                </Button>
                {/* easter egg for bb */}
                {showUnenroll ? (
                  <Button
                    sx={{ ml: 1 }}
                    color="error"
                    disabled={verityRefreshQ.isFetching}
                    onClick={async () => {
                      if (season?.id) {
                        // eslint-disable-next-line no-alert
                        const forReal = window.confirm("Are you sure?");
                        if (forReal) {
                          axios
                            .delete(
                              `${org}/verity/ci/fields/seasons/${season?.id}`
                            )
                            .then(() => {
                              setShouldRefresh(true);
                            })
                            .finally(() => {
                              setShowUnenroll(false);
                            });
                        }
                      }
                    }}
                    variant="outlined"
                  >
                    {"UNENROLL ALL FOR SEASON"}
                  </Button>
                ) : null}
              </>
            ),
          }}
          columns={columns}
          data={verityQ.data}
        />
      </Paper>
    </Stack>
  );
}
