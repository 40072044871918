import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "lib";
import { useSettingsState } from "views/SettingsView/useSettingsStore";
import { Route, Switch } from "react-router-dom";
import App from "./app";
import LoginView from "views/LoginView";
import { toMuiLanguageCode } from "lib/theme/toMuiLanguageCode";

export default function Routes() {
  const { theme, language } = useSettingsState();
  const lang = toMuiLanguageCode(language);
  return (
    <ThemeProvider mode={theme} locale={lang}>
      <CssBaseline />
      <Switch>
        <Route path="/" exact>
          <LoginView />
        </Route>
        <Route path="*">
          <App />
        </Route>
      </Switch>
    </ThemeProvider>
  );
}
