// see default locale: https://github.com/mapbox/mapbox-gl-js/blob/main/src/ui/default_locale.ts

const es_locale = {
  "AttributionControl.ToggleAttribution": "Alternar atribución",
  "AttributionControl.MapFeedback": "Comentarios sobre el mapa",
  "FullscreenControl.Enter": "Entrar en pantalla completa",
  "FullscreenControl.Exit": "Salir de pantalla completa",
  "GeolocateControl.FindMyLocation": "Buscar mi ubicación",
  "GeolocateControl.LocationNotAvailable": "Ubicación no disponible",
  "LogoControl.Title": "Logotipo de Mapbox",
  "Map.Title": "Mapa",
  "NavigationControl.GoToDefaultExtent": "Ir a la extensión por defecto",
  "NavigationControl.ResetBearing": "Reajustar el rumbo al norte",
  "NavigationControl.ZoomIn": "Ampliar",
  "NavigationControl.ZoomOut": "Alejar",
  "ScrollZoomBlocker.CtrlMessage": "Usa ctrl + scroll para ampliar el mapa",
  "ScrollZoomBlocker.CmdMessage": "Usa ⌘+ scroll para ampliar el mapa",
  "TouchPanBlocker.Message": "Usa dos dedos para mover el mapa",
  "ToggleableControl.Title": "Conmutar el control del mapa",
};

const fr_locale = {
  "AttributionControl.ToggleAttribution": "Basculer l'attribution",
  "AttributionControl.MapFeedback": "Retour à la carte",
  "FullscreenControl.Enter": "Entrer en plein écran",
  "FullscreenControl.Exit": "Quitter le plein écran",
  "GeolocateControl.FindMyLocation": "Trouver mon emplacement",
  "GeolocateControl.LocationNotAvailable": "Localisation non disponible",
  "LogoControl.Title": "Logo Mapbox",
  "Map.Title": "Carte",
  "NavigationControl.GoToDefaultExtent": "Aller à l'étendue par défaut",
  "NavigationControl.ResetBearing": "Remettre le cap au nord",
  "NavigationControl.ZoomIn": "Zoom avant",
  "NavigationControl.ZoomOut": "Zoom arrière",
  "ScrollZoomBlocker.CtrlMessage":
    "Utilisez ctrl + scroll pour zoomer sur la carte",
  "ScrollZoomBlocker.CmdMessage":
    "Utilisez ⌘ + scroll pour zoomer sur la carte",
  "TouchPanBlocker.Message": "Utilisez deux doigts pour déplacer la carte",
  "ToggleableControl.Title": "Basculer le contrôle de la carte",
};

const en_locale = {
  "NavigationControl.GoToDefaultExtent": "Go to default extent",
  "ToggleableControl.Title": "Toggle map control",
  "AttributionControl.ToggleAttribution": "Toggle attribution",
  "FullscreenControl.Enter": "Enter fullscreen",
  "FullscreenControl.Exit": "Exit fullscreen",
  "GeolocateControl.FindMyLocation": "Find my location",
  "GeolocateControl.LocationNotAvailable": "Location not available",
  "LogoControl.Title": "Mapbox homepage",
  "Map.Title": "Map",
  "NavigationControl.ResetBearing": "Reset bearing to north",
  "NavigationControl.ZoomIn": "Zoom in",
  "NavigationControl.ZoomOut": "Zoom out",
  "ScrollZoomBlocker.CtrlMessage": "Use ctrl + scroll to zoom the map",
  "ScrollZoomBlocker.CmdMessage": "Use ⌘ + scroll to zoom the map",
  "TouchPanBlocker.Message": "Use two fingers to move the map",
};

const hi_locale = {
  "NavigationControl.GoToDefaultExtent": "डिफ़ॉल्ट सीमा पर जाएं",
  "ToggleableControl.Title": "नक्शा नियंत्रण टॉगल करें",
  "AttributionControl.ToggleAttribution": "एट्रिब्यूशन टॉगल करें",
  "FullscreenControl.Enter": "पूर्ण स्क्रीन में जाएं",
  "FullscreenControl.Exit": "पूर्ण स्क्रीन से बाहर निकलें",
  "GeolocateControl.FindMyLocation": "मेरा स्थान खोजें",
  "GeolocateControl.LocationNotAvailable": "स्थान उपलब्ध नहीं है",
  "LogoControl.Title": "Mapbox होमपेज",
  "Map.Title": "नक्शा",
  "NavigationControl.ResetBearing": "दिशा को उत्तर पर रीसेट करें",
  "NavigationControl.ZoomIn": "ज़ूम इन करें",
  "NavigationControl.ZoomOut": "ज़ूम आउट करें",
  "ScrollZoomBlocker.CtrlMessage":
    "नक्शे को ज़ूम करने के लिए Ctrl + स्क्रॉल का उपयोग करें",
  "ScrollZoomBlocker.CmdMessage":
    "नक्शे को ज़ूम करने के लिए ⌘ + स्क्रॉल का उपयोग करें",
  "TouchPanBlocker.Message":
    "नक्शे को स्थानांतरित करने के लिए दो उंगलियों का उपयोग करें",
};

const mapboxLocales = {
  es_locale,
  fr_locale,
  en_locale,
  hi_locale,
};

export default mapboxLocales;
