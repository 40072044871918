import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { randomPoint } from "@turf/random";
import booleanContains from "@turf/boolean-contains";
import turfFlatten from "@turf/flatten";
import { Feature, FeatureCollection, Geometry } from "geojson";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import BaseForm from "components/Forms/BaseForm";
import { IField, ISelectOption } from "types";
import { IAnimalBreed } from "types/IAssetType";
import useOrgLookupRQ from "components/useOrgLookupRQ";
import { useOrgState } from "providers/OrgProvider";
import { ManualSwitchFormField } from "./FormFields";
import { useInventoryDispatch, useInventoryState } from "providers";
import { csvToArray } from "components/csvToArray";
import { setFeatureCollectionProps } from "app-utils";
import { AllGeoJSON } from "@turf/helpers";
import { AlertCircle } from "react-feather";
import { GeoDataTable } from "components";
import { validateFeatureCounts } from "./asset-upload-utils";
import { useAssetsUpload } from "api/useAssetsUpload";
import FieldSelect from "components/FieldSelect";
import { useFields } from "api/fields/useFields";
import AssetGroupSelect from "./AssetGroupSelect";
import Autocomplete from "lib/Select/Autocomplete";
import { useAnimalBreeds } from "api/assets/useAnimalBreeds";
import { filterOption } from "./AnimalAssetForm";
import { DatePicker } from "lib/DatePicker";

function isInside(ftrs: Feature[], ftr: Feature) {
  try {
    return ftrs.find((f) => {
      return booleanContains(f, ftr);
    });
  } catch (e) {
    console.error("Failed verifying isInside", e.message);
  }
}

function getRandomPoints(count: number, geom: AllGeoJSON) {
  try {
    const flattened = turfFlatten(geom);
    const fc = randomPoint(count, { bbox: geom.bbox });
    const final = [];
    for (let i = 0; i < fc.features.length; i++) {
      let pnt = fc.features[i];
      while (!isInside(flattened.features, pnt)) {
        pnt = randomPoint(1, { bbox: geom.bbox }).features[0];
      }
      final.push(pnt);
    }
    return final;
  } catch (e) {
    console.error("Failed generating points", e.message);
  }
}

export default function AnimalAssetUpload({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { org, season } = useOrgState();
  const fieldsQ = useFields({ org, seasonId: season?.id });
  const {
    getFieldGeodata,
    zoomToField,
    setInventoryFtrs,
    highlightLayerFeatures,
  } = useInventoryDispatch();
  const { inventoryMap, ftrsClicked } = useInventoryState();

  const { data: assetStatuses } = useOrgLookupRQ(
    `/${org}/lookups/assetstatuses`
  );

  const [uploadedFc, setUploadedFc] = useState<FeatureCollection>();
  const [parsedKeys, setParsedKeys] = useState<ISelectOption[]>([]);
  const [parseError, setParseError] = useState("");
  const [selectedField, setSelectedField] = useState<IField>();
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [assetStatusId, setAssetStatusId] = useState("");
  const [selectedBreedType, setSelectedAnimalBreed] = useState<IAnimalBreed>();

  const breedsQ = useAnimalBreeds(org);

  // prop mappings
  const [cidProp, setCidProp] = useState("");
  const [bornOnProp, setBornOnProp] = useState("");
  const [nameProp, setNameProp] = useState("");
  const [descriptionProp, setDescriptionProp] = useState("");
  const [isFemaleProp, setIsFemaleProp] = useState("");
  // manual inputs
  const [manualDate, setManualDate] = useState<Date>();
  const [manualDescription, setManualDescription] = useState("");
  const [manualName, setManualName] = useState("");
  const [manualIsFemale, setManualIsFemale] = useState<boolean>(null);

  const { uploadAssets, ...query } = useAssetsUpload({
    org,
    seasonId: season.id,
    type: "animal",
  });

  function reset() {
    setParsedKeys([]);
    setParseError("");
    setIsFemaleProp("");
    setCidProp("");
    setNameProp("");
    setBornOnProp("");
    setDescriptionProp("");
    setManualDescription("");
    setManualDate(null);
    setManualName("");
    setManualIsFemale(null);
  }

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    reset();

    if (!file) {
      setUploadedFc(null);
      return;
    }
    const nameSplit = file.name.split(".");
    const ext = nameSplit[nameSplit.length - 1];
    if (!["txt", "csv"].includes(ext)) {
      setUploadedFc(null);
      setParseError(t("common.couldNotLoadFile"));
      return;
    }
    // ~4mb
    if (file.size > 4000000) {
      setUploadedFc(null);
      setParseError(`${t("common.maxFileSize")}: 4mb`);
      return;
    }
    try {
      const reader = new FileReader();

      reader.onload = function (e) {
        const text = e.target.result as string;
        const asArr = csvToArray(text);
        if (asArr.length > 1000) {
          setParseError(
            `${t("common.maxFeatureCount")}: ${(1000).toLocaleString()}`
          );
          setUploadedFc(null);
          return;
        }
        const fc = {
          type: "FeatureCollection",
          features: asArr.map((a, idx) => ({
            type: "Feature",
            id: a.id ?? `genId-${idx}`,
            geometry: null as Geometry,
            properties: a,
          })),
        } as FeatureCollection;

        const ftr = getFieldGeodata(selectedField?.id);
        if (ftr) {
          const final = getRandomPoints(asArr.length, ftr.geometry);
          fc.features.forEach((f, i) => {
            f.geometry = final[i].geometry;
          });
          setUploadedFc({ ...fc });
          const ftrsWithColor = setFeatureCollectionProps(fc, {
            color: selectedBreedType?.color || "#eee",
            assetAnimalBreed: selectedBreedType?.name ?? "??",
          });
          setInventoryFtrs(ftrsWithColor);
        } else {
          setUploadedFc(fc);
        }

        const keys = Object.keys(asArr[0]);
        setParsedKeys(
          keys.map((k) => ({
            value: k,
            label: k,
          }))
        );
      };
      reader.readAsText(file);
    } catch (e) {
      setParseError(t("common.couldNotLoadFile"));
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "cid",
        Cell: ({ value }: { value: unknown }) => {
          const val =
            value && typeof value === "object" ? JSON.stringify(value) : value;
          return val;
        },
      },
      {
        Header: `${t("common.name")}`,
        accessor: "name",
        Cell: ({ value }: { value: unknown }) => {
          const val =
            value && typeof value === "object" ? JSON.stringify(value) : value;
          return <div>{val ?? manualName}</div>;
        },
      },
      {
        Header: `${t("inventory.assets.sex")} *`,
        accessor: "isFemale",
        Cell: ({ value }: { value: boolean }) => {
          const val =
            value === true
              ? t("inventory.assets.female")
              : value === false
              ? t("inventory.assets.male")
              : null;
          return (
            <Typography color={val === null ? "error.main" : undefined}>
              {val ?? (
                <>
                  <AlertCircle />
                </>
              )}
            </Typography>
          );
        },
      },
      {
        Header: `${t("inventory.assets.bornOn")} *`,
        accessor: "bornOnUtc",
        Cell: ({ value }: { value: string }) => {
          const asString =
            value && typeof value === "object" ? JSON.stringify(value) : value;
          const val = manualDate ? manualDate.toLocaleDateString() : asString;
          return (
            <Typography color={!val ? "error.main" : undefined}>
              {val || (
                <>
                  <AlertCircle />
                </>
              )}
            </Typography>
          );
        },
      },
      {
        Header: t("common.notes"),
        accessor: "description",
        Cell: ({ value }: { value: string }) => {
          const asString =
            value && typeof value === "object" ? JSON.stringify(value) : value;
          return <div>{asString ?? manualDescription}</div>;
        },
      },
    ],
    [t, manualName, manualDate, manualDescription]
  );

  const formattedFC = React.useMemo(() => {
    if (!uploadedFc) {
      return null;
    }
    const formatted = setFeatureCollectionProps(
      uploadedFc,
      {
        assetStatusId,
        fieldId: selectedField?.id,
        assetGroupId: selectedGroupId,
        assetAnimalBreedId: selectedBreedType?.id,
        ...(manualDate
          ? {
              bornOnUtc: manualDate.toISOString(),
            }
          : {}),
        ...(manualDescription ? { description: manualDescription } : {}),
        ...(manualName ? { name: manualName } : {}),
        ...(manualIsFemale !== null ? { isFemale: manualIsFemale } : {}),
      },
      {
        name: nameProp,
        description: descriptionProp,
        bornOnUtc: bornOnProp,
        cid: cidProp,
        isFemale: isFemaleProp,
      }
    );
    return formatted;
  }, [
    uploadedFc,
    assetStatusId,
    selectedField,
    selectedGroupId,
    selectedBreedType?.id,
    manualDate,
    manualDescription,
    manualName,
    manualIsFemale,
    nameProp,
    descriptionProp,
    bornOnProp,
    cidProp,
    isFemaleProp,
  ]);
  const tableData = React.useMemo(
    () =>
      formattedFC?.features.map((f) => ({ id: f.id, ...f.properties })) ?? [],
    [formattedFC?.features]
  );

  const { invalidCount, validCount } = validateFeatureCounts(formattedFC, [
    "isFemale",
    "bornOnUtc",
  ]);

  return (
    <>
      <BaseForm
        saveState={{
          isLoading: query.isLoading,
          isError: query.isError,
          errorMessage: query.error?.message,
        }}
        cancelHref="./"
        onSubmit={() => {
          if (!uploadedFc) {
            setParseError(t("common.requiredField"));
            return false;
          }
          if (invalidCount) {
            return false;
          }
          return uploadAssets(formattedFC, {
            onSuccess: () => {
              onSuccess();
              history.push("./");
            },
          });
        }}
      >
        <Stack spacing={2}>
          <FieldSelect
            required
            onChange={(fld) => {
              setSelectedField(fld);
              if (fld?.id) {
                const geoData = zoomToField(fld.id);
                if (uploadedFc) {
                  const final = getRandomPoints(
                    uploadedFc.features.length,
                    geoData.geometry
                  );
                  uploadedFc.features.forEach((f, i) => {
                    f.geometry = final[i].geometry;
                  });
                  const ftrsWithColor = setFeatureCollectionProps(uploadedFc, {
                    color: selectedBreedType?.color || "#eee",
                    assetAnimalBreed: selectedBreedType?.name ?? "??",
                  });
                  setUploadedFc({ ...uploadedFc });
                  setInventoryFtrs(ftrsWithColor);
                }
              }
            }}
            fields={fieldsQ.data ?? []}
          />
          <AssetGroupSelect
            value={selectedGroupId}
            onChange={(id) => {
              setSelectedGroupId(id);
            }}
          />
          <Autocomplete
            label={`${t("common.breed")} *`}
            disableClearable={false}
            // error={!!errors?.assetAnimalBreedId}
            options={breedsQ.data || []}
            filterOptions={(data: IAnimalBreed[], input) => {
              return data.filter((d) => {
                return filterOption(d, input.inputValue);
              });
            }}
            groupBy={(o) => o.assetAnimalTypeName}
            value={
              breedsQ.data?.find((f) => f.id === selectedBreedType?.id) ?? null
            }
            onChange={(_e, item) => {
              const opt = item as IAnimalBreed;
              setSelectedAnimalBreed(opt);
            }}
          />
          <Autocomplete
            label={`${t("common.status")} *`}
            InputProps={{
              required: true,
            }}
            options={assetStatuses}
            value={
              assetStatuses?.find((ao) => ao.value === assetStatusId) ?? null
            }
            onChange={(_e, item) => {
              const opt = item as { value: string };
              setAssetStatusId(opt.value);
            }}
            disableClearable={false}
          />
          {selectedField && selectedBreedType && assetStatusId ? (
            <>
              <Stack direction={"row"} alignItems={"center"}>
                <input
                  style={{ flex: 1 }}
                  onChange={handleFile}
                  type="file"
                  accept=".txt, .csv"
                />
                <Typography
                  variant="body2"
                  sx={{ ml: "auto" }}
                  color="info.main"
                >
                  CSV
                </Typography>
              </Stack>
              {parseError ? (
                <Typography color="error.main">{parseError}</Typography>
              ) : null}
            </>
          ) : null}
          {formattedFC ? (
            <>
              <Autocomplete
                label="ID"
                disableClearable={false}
                value={
                  cidProp ? parsedKeys?.find((p) => p.value === cidProp) : null
                }
                options={parsedKeys}
                onChange={(_e, item: { value: string }) => {
                  setCidProp(item?.value);
                }}
              />
              <ManualSwitchFormField
                id="manual-name"
                onChange={(e) => {
                  if (!e.currentTarget.checked) {
                    setManualName("");
                  } else {
                    setNameProp("");
                  }
                }}
                manualInput={
                  <TextField
                    fullWidth
                    label={`${t("common.name")}`}
                    inputProps={{
                      name: "name",
                      maxLength: 50,
                    }}
                    onChange={(e) => {
                      setManualName(e.target.value);
                    }}
                  />
                }
              >
                <Autocomplete
                  label={`${t("common.name")}`}
                  InputProps={{ fullWidth: true }}
                  disableClearable={false}
                  value={
                    nameProp
                      ? parsedKeys?.find((p) => p.value === nameProp)
                      : null
                  }
                  options={parsedKeys}
                  onChange={(_e, item: { value: string }) => {
                    setNameProp(item?.value);
                  }}
                />
              </ManualSwitchFormField>
              <ManualSwitchFormField
                id="manual-sex"
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    setIsFemaleProp("");
                    setManualIsFemale(false);
                  } else {
                    setManualIsFemale(null);
                  }
                }}
                manualInput={
                  <FormControl
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <FormLabel sx={{ mr: 2 }} id="gender-radio">
                      {`${t("inventory.assets.sex")} *`}
                    </FormLabel>
                    <RadioGroup
                      row
                      value={manualIsFemale}
                      onChange={(e) => {
                        const isFemale = e.target.value === "true";
                        setManualIsFemale(isFemale);
                      }}
                      aria-labelledby="gender-radio"
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={t("inventory.assets.male")}
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={t("inventory.assets.female")}
                      />
                    </RadioGroup>
                  </FormControl>
                }
              >
                <Autocomplete
                  label={`${t("inventory.assets.sex")} *`}
                  disableClearable={false}
                  InputProps={{
                    required: true,
                  }}
                  value={
                    isFemaleProp
                      ? parsedKeys?.find((p) => p.value === isFemaleProp)
                      : null
                  }
                  options={parsedKeys}
                  onChange={(_e, item: { value: string }) => {
                    setIsFemaleProp(item?.value);
                  }}
                />
              </ManualSwitchFormField>
              <ManualSwitchFormField
                id="born-on-date"
                onChange={(e) => {
                  if (!e.currentTarget.checked) {
                    setManualDate(null);
                  } else {
                    setBornOnProp("");
                  }
                }}
                manualInput={
                  <DatePicker
                    label={`${t("inventory.assets.bornOn")}`}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        required: true,
                      },
                    }}
                    maxDate={new Date()}
                    value={manualDate}
                    onChange={(d: Date) => {
                      setManualDate(d);
                    }}
                  />
                }
              >
                <Autocomplete
                  disableClearable={false}
                  label={`${t("inventory.assets.bornOn")} *`}
                  InputProps={{
                    required: true,
                  }}
                  value={
                    bornOnProp
                      ? parsedKeys?.find((p) => p.value === bornOnProp)
                      : null
                  }
                  options={parsedKeys}
                  onChange={(_e, item: { value: string }) => {
                    setBornOnProp(item?.value);
                  }}
                />
              </ManualSwitchFormField>
              <ManualSwitchFormField
                id="manual-description"
                onChange={(e) => {
                  if (!e.currentTarget.checked) {
                    setManualDescription(null);
                  } else {
                    setDescriptionProp("");
                  }
                }}
                manualInput={
                  <TextField
                    fullWidth
                    label={t("common.notes")}
                    onChange={(e) => {
                      setManualDescription(e.target.value);
                    }}
                    multiline
                    inputProps={{
                      maxLength: 255,
                    }}
                  />
                }
              >
                <Autocomplete
                  label={t("common.notes")}
                  disableClearable={false}
                  value={
                    parsedKeys?.find((p) => p.value === descriptionProp) ?? null
                  }
                  options={parsedKeys}
                  onChange={(_e, item: { value: string }) => {
                    setDescriptionProp(item?.value);
                  }}
                />
              </ManualSwitchFormField>
              <Typography
                variant="body2"
                color={invalidCount ? "error.main" : "info.main"}
              >
                {`${t("common.validFeatures")}: ${validCount} /
                  ${formattedFC.features.length}`}
              </Typography>
            </>
          ) : null}
        </Stack>
      </BaseForm>
      {formattedFC ? (
        <div
          className="inventory-list"
          style={{ overflow: "auto", minHeight: "400px" }}
        >
          <GeoDataTable
            isFilterable={false}
            highlightByIds={highlightLayerFeatures}
            ftrsClicked={ftrsClicked}
            map={inventoryMap}
            featureCollection={formattedFC}
            data={tableData}
            columns={columns}
          />
        </div>
      ) : null}
    </>
  );
}
