import { useQueryClient } from "@tanstack/react-query";
import { getPreferredLanguage } from "lib/theme/getPreferredLanguage";
import { useTranslation } from "react-i18next";
import { ISettingsConfig } from "types";
import { create } from "zustand";

export const settingsStoreId = "cai-agassessor-settings";
export const languagePrefId = "cai:lang:preference";

const defaults = {
  theme: "dark",
  language: getPreferredLanguage(),
};

function getCache() {
  if (window.localStorage) {
    const currentSettings = window.localStorage.getItem(settingsStoreId);
    if (currentSettings) {
      try {
        const obj = JSON.parse(currentSettings);
        obj.language = defaults.language;
        return obj;
      } catch (e) {
        return defaults;
      }
    } else {
      return defaults;
    }
  } else {
    return defaults;
  }
}

function useLanguages() {
  const { t } = useTranslation();
  return [
    { label: t("settings.english"), value: "en-US" },
    { label: t("settings.spanish"), value: "es-ES" },
    { label: t("settings.french"), value: "fr-FR" },
    { label: t("settings.hindi"), value: "hi-IN" },
  ];
}

export const useSettingsStore = create<
  ISettingsConfig & {
    saveSettings: (_settings: Partial<ISettingsConfig>) => void;
  }
>((set) => {
  return {
    ...getCache(),
    saveSettings: (settings: ISettingsConfig) => {
      if (
        document.body.classList.contains("light") &&
        settings.theme === "dark"
      ) {
        document.body.classList.remove("light");
      }
      if (window.localStorage) {
        const { language, ...rest } = settings;
        const lng = language.slice(0, 2);
        window.localStorage.setItem(settingsStoreId, JSON.stringify(rest));
        window.localStorage.setItem(languagePrefId, lng);
      }
      return set((state) => ({
        ...state,
        ...settings,
      }));
    },
  };
});

export const useSettingsState = () => {
  const languages = useLanguages();
  return useSettingsStore((state) => {
    return {
      language: state.language,
      languages,
      theme: state.theme,
    };
  });
};

export const useSettingsDispatch = () => {
  const queryClient = useQueryClient();

  return useSettingsStore((state) => {
    return (d: Partial<ISettingsConfig>) => {
      state.saveSettings({
        ...state,
        ...d,
      });
      // force re-fetch for everything to get API provided translations
      queryClient.invalidateQueries({ queryKey: ["organization"] });
    };
  });
};
