export function toMuiLanguageCode(lang: string) {
  // we just want to check ISO code
  const langCode = lang.substring(0, 2);
  if (langCode === "es") {
    return "esES";
  } else if (langCode === "fr") {
    return "frFR";
  } else if (langCode === "hi") {
    return "hiIN";
  }
  // default to english
  return "enUS";
}
