export function getPreferredLanguage(langPrefId = "cai:lang:preference") {
  const supported = ["en", "es", "fr", "hi"];
  try {
    const prefLng = window.localStorage.getItem(langPrefId);
    const browserLng = navigator?.language?.split("-")[0];
    const supportedBrowserLng = supported.includes(browserLng);

    const final = prefLng
      ? prefLng
      : supportedBrowserLng
      ? navigator?.language
      : "en-US";
    return final;
  } catch (e) {
    console.error("Failed getting language preference");
    return "en-US";
  }
}
