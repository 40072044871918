import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { VerityFieldCI } from "./VerityFieldCI";

export function StatusCell({
  disabled,
  isLoading,
  onClick,
  value,
  row,
}: {
  disabled?: boolean;
  isLoading?: boolean;
  onClick: (_d: VerityFieldCI) => void;
  org: string;
  value?: string;
  row: { original: VerityFieldCI };
  seasonId: string;
  programId: string;
}) {
  const key = row?.original?.statusEn;
  return (
    <div>
      <Button
        endIcon={isLoading ? <CircularProgress size={16} /> : null}
        disabled={
          disabled ||
          isLoading ||
          !value ||
          key === "certified" ||
          key === "enrolled"
        }
        onClick={() => {
          return onClick(row.original);
        }}
        variant="contained"
      >
        {value ?? "--"}
      </Button>
    </div>
  );
}
